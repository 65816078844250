// src/components/Home.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function Home() {
  const [id, setId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showForm, setShowForm] = useState(false);

  const navigate = useNavigate();
  const { interviewId } = useParams();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!token) {
      setErrorMessage('Token is missing. Please use a valid interview link.');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp < currentTime) {
        setErrorMessage('The interview link has expired.');
        return;
      }

      // Store the token in localStorage for use across other screens
      localStorage.setItem('authToken', token);
      setShowForm(true);
      if (interviewId) {
        navigate(`/interview/${interviewId}`);
      }
    } catch (error) {
      setErrorMessage('Invalid token. Please use a valid interview link.');
    }
  }, [location.search]);

  const handleSubmit = () => {
    if (interviewId || id.trim()) {
      const finalId = interviewId || id.trim();
      localStorage.setItem('interviewId', finalId);
      navigate(`/interview/${interviewId}`);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-blue-900">
      {errorMessage ? (
        <div className="bg-white shadow-md rounded-lg p-8 w-96 text-center">
          <p className="text-red-500 text-lg font-semibold">{errorMessage}</p>
        </div>
      ) : showForm ? (
        <div className="bg-white shadow-md rounded-lg p-8 w-96 text-center">
          <label className="block text-gray-700 text-lg font-semibold mb-4">Enter the Interview ID</label>
          {!interviewId && (
            <input
              type="text"
              placeholder="Enter Interview ID"
              value={id}
              onChange={(e) => setId(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
            />
          )}
          <button
            onClick={handleSubmit}
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      ) : (
        <div className="bg-white shadow-md rounded-lg p-8 w-96 text-center">
          <p className="text-gray-700 text-lg font-semibold">
            Validating token...
          </p>
        </div>
      )}
    </div>
  );
}

export default Home;
