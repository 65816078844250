import React from 'react';
import { GridLoader } from 'react-spinners';

const StartingInterviewPopup = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg backdrop-blur-md">
        <GridLoader size={12} color="#4A90E2" />
        <div className="text-lg mt-4 text-gray-700 text-center">
          Starting. One Moment Please...
        </div>
      </div>
    </div>
  );
};

export default StartingInterviewPopup;
