import { useSpeakingParticipants } from "@livekit/components-react";
import StartingInterviewPopup from './StartingInterviewPopup';
import { useState } from "react";

const ParticipantAvatar = ({ type, color, label }) => {
  const [showPreparing, setShowPreparing] = useState(true);
  const speakingParticipants = useSpeakingParticipants();
  const participantId =
    type === 'agent'
      ? speakingParticipants.filter((participant) =>
        participant.identity.startsWith('agent')
      )[0]?.identity
      : speakingParticipants.filter(
        (participant) => !participant.identity.startsWith('agent')
      )[0]?.identity;

  // Check if the participant is currently speaking
  const isSpeaking = speakingParticipants.some(
    (participant) => participant.identity === participantId
  );
  if (isSpeaking && showPreparing) {
    console.log(`Hiding`);
    setShowPreparing(false);
  }

  // Define a mapping of valid colors
  const colorClasses = {
    red: "bg-red-500",
    blue: "bg-blue-500",
    green: "bg-green-500",
    yellow: "bg-yellow-500",
    purple: "bg-purple-500",
    // Add more colors as needed
  };

  // Fallback to a default color if the provided color is invalid
  const bgColorClass = colorClasses[color] || "bg-gray-500";

  return (
    <div className="relative flex items-center justify-center pt-32">
      {type === 'agent' && showPreparing === true && <StartingInterviewPopup />}
      {isSpeaking && (
        <div
          className="absolute rounded-full bg-opacity-50 bg-white animate-ping"
          style={{
            width: 150,
            height: 150,
          }}
        ></div>
      )}
      <div
        className={`w-40 h-40 rounded-full ${bgColorClass} flex items-center justify-center text-white text-4xl font-bold relative`}
        style={{ zIndex: 10 }}
      >
        {label}
      </div>
    </div>
  );
};

export default ParticipantAvatar;
