import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const TOKEN_SERVER_URL = process.env.REACT_APP_TOKEN_SERVER_URL;
const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

/**
 * Get a LiveKit token for the interview.
 */
export const getToken = async (interviewId) => {
  try {
    const response = await axios.post(`${TOKEN_SERVER_URL}/${interviewId}/createToken`, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

/**
 * Get full details of an interview.
 */
export const getInterviewDetails = async (interviewId) => {
  try {
    const response = await axios.get(`${API_SERVER_URL}/interviews/${interviewId}/full`);
    return response.data;
  } catch (error) {
    console.error('Error fetching interview details:', error);
    throw error;
  }
};

/**
 * Update the language for the interview.
 */
export const updateInterviewLanguage = async (interviewId, language) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.patch(
      `${API_SERVER_URL}/interviews/${interviewId}/language`,
      { language },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating interview language:', error);
    throw error;
  }
};

/**
 * Start the interview via API.
 */
export const startInterview = async (interviewId, language) => {
  try {
    const authToken = localStorage.getItem('authToken');
    const response = await axios.post(
      `${API_SERVER_URL}/interviews/${interviewId}/start`,
      { language },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error starting the interview:', error);
    throw error;
  }
};

/**
 * Validate the authentication token.
 */
export const validateAuthToken = () => {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) {
    throw new Error('Authentication token is missing. Please log in again.');
  }

  try {
    const decodedToken = jwtDecode(authToken);
    const currentTime = Math.floor(Date.now() / 1000);

    if (decodedToken.exp < currentTime) {
      throw new Error('Authentication token has expired. Please log in again.');
    }
  } catch (error) {
    throw new Error('Invalid authentication token. Please log in again.');
  }
};

/**
 * End the interview via API.
 */
export const endInterview = async () => {
  try {
    const authToken = localStorage.getItem('authToken');
    const interviewId = localStorage.getItem('interviewId');
    const response = await axios.post(
      `${API_SERVER_URL}/interviews/${interviewId}/end`,
      { applicationToken: authToken }
    );
    return response.data;
  } catch (error) {
    console.error('Error starting the interview:', error);
    throw error;
  }
};